import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import axios from 'axios';
import STATUSES from '../Statuses';
// import createApiSlice from "../Slices/Common/CommoCreateSlice";
import config from '../../config';

const serviceURL = config.SERVICE_TRACEABILITY_URL;



// =============================================== Check List APIS ============================================
const CheckListSlice = createSlice({
    name : "CheckList",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetCheckListState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            isAnyOf(saveCheckListData.pending , updateCheckListData.pending , deleteCheckListData.pending , saveUseCheckListData.pending , updateUseCheckListData.pending , deleteUseCheckListData.pending),
            (state) => {
                state.status = STATUSES.LOADING;
            }
        );

        builder.addMatcher(
            isAnyOf(saveCheckListData.fulfilled, updateCheckListData.fulfilled , deleteCheckListData.fulfilled , saveUseCheckListData.fulfilled, updateUseCheckListData.fulfilled , deleteUseCheckListData.fulfilled),
            (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.SUCCESS;
                state.error = null;
            }
        );

        builder.addMatcher(
            isAnyOf(saveCheckListData.rejected , updateCheckListData.rejected , deleteCheckListData.rejected , saveUseCheckListData.rejected , updateUseCheckListData.rejected , deleteUseCheckListData.rejected),
            (state, action) => {
                state.error = action.payload;
                state.status = STATUSES.ERROR;
            }
        );
    },
}); 

//  =========================================  CheckList APIS ============================================


// get data from CheckList
export const getCheckListData = createAsyncThunk( "checkList/get", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.get(`${serviceURL}/checklists/getAllChecklists`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// save Data in CheckList
export const saveCheckListData = createAsyncThunk( "checkList/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.post(`${serviceURL}/checklists/createChecklist`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// update Data in CheckList
export const updateCheckListData = createAsyncThunk( "checkList/update", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { id , ...restData } = data;
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.put(`${serviceURL}/checklists/${id}`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// delete data from CheckList
export const deleteCheckListData = createAsyncThunk( "checkList/delete", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.delete(`${serviceURL}/checklists/${data.id}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})














// =============================================== USE Check List APIS ============================================


// get data from  useCheckList
export const getUseCheckListData = createAsyncThunk( "useCheckList/get", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.get(`${serviceURL}/checklists/getAllChecklists-newwwwwwwwwwww`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// save Data in CheckList
export const saveUseCheckListData = createAsyncThunk( "useCheckList/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.post(`${serviceURL}/useOfChecklists`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// update Data in CheckList
export const updateUseCheckListData = createAsyncThunk( "useCheckList/update", async ( data, { rejectWithValue ,getState })=>{
    try {
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.put(`${serviceURL}/useOfChecklists`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// delete data from CheckList
export const deleteUseCheckListData = createAsyncThunk( "useCheckList/delete", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.delete(`${serviceURL}/useOfChecklists/${data.userId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})








// ================================================ Assign and favourite checklist API =============================================

// Save Assign CheckList Data
export const saveAssignCheckList = createAsyncThunk( "assignCheckList/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { myUserId , ...restData } = data;

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json"},
            params : { currentUserId : myUserId }
        };

        const responce = await axios.post(`${serviceURL}/useOfChecklists/assign`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// Save favourite CheckList Data
export const saveFavouriteCheckList = createAsyncThunk( "favouriteCheckList/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { myUserId , isRemove , ...restData } = data;

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json"},
            params : { currentUserId : myUserId  , isRemove :isRemove }
        };

        const responce = await axios.post(`${serviceURL}/useOfChecklists/markAsFavChecklist`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// get data from  useCheckList
export const getFavAndAssignedCheckList = createAsyncThunk( "favAndAssignedCheckList/get", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
        };

        const responce = await axios.get(`${serviceURL}/useOfChecklists/getAllFavAndAssignedChecklists/${data.userId}`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})















export {
    CheckListSlice,
}

export const {resetCheckListState} = CheckListSlice.actions;



