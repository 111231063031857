import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import axios from 'axios';
import STATUSES from '../Statuses';
// import createApiSlice from "../Slices/Common/CommoCreateSlice";
import config from '../../config';

const serviceURL = config.SERVICE_TRACEABILITY_URL;

 

// =============================================== Toolkit Table APIS ============================================
const ToolKitSlice = createSlice({
    name : "ToolKit",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetToolKitState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            isAnyOf(createToolKit.pending),
            (state) => {
                state.status = STATUSES.LOADING;
            }
        );

        builder.addMatcher(
            isAnyOf(createToolKit.fulfilled,),
            (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.SUCCESS;
                state.error = null;
            }
        );

        builder.addMatcher(
            isAnyOf(createToolKit.rejected),
            (state, action) => {
                state.error = action.payload;
                state.status = STATUSES.ERROR;
            }
        );
    },
}); 



// =============================================== Dynamic Table APIS ============================================
const DynamicTableSlice = createSlice({
    name : "DynamicTable",
    initialState : {
        data :{},
        status : STATUSES.IDEAL,
        error : null
    },
    reducers : {
        resetDynamicTableState :((state , action)=>{
            state.error = null
            state.status = STATUSES.IDEAL
            state.data = {}
        })
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            isAnyOf(saveDataInTable.pending , updateDataInTable.pending , deleteTableData.pending),
            (state) => {
                state.status = STATUSES.LOADING;
            }
        );

        builder.addMatcher(
            isAnyOf(saveDataInTable.fulfilled, updateDataInTable.fulfilled , deleteTableData.fulfilled),
            (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.SUCCESS;
                state.error = null;
            }
        );

        builder.addMatcher(
            isAnyOf(saveDataInTable.rejected , updateDataInTable.rejected , deleteTableData.rejected),
            (state, action) => {
                state.error = action.payload;
                state.status = STATUSES.ERROR;
            }
        );
    },
}); 














// create toolkit
export const createToolKit = createAsyncThunk( "Toolkit/create", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params : {collectionName : data.toolkitName}
        };

        const responce = await axios.post(`${serviceURL}/dynamic/create-collection`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// create empty tolkit
export const createEmptyToolKit = createAsyncThunk( "EmptyToolkit/create", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params : {collectionName : data.toolkitName}
        };

        const responce = await axios.post(`${serviceURL}/dynamic/create-empty-collection`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// update toolkit
export const updateToolKit = createAsyncThunk( "Toolkit/update", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params : {collectionName : data.toolkitName}
        };

        const responce = await axios.post(`${serviceURL}/dynamic/update-collection-schema`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

// 
export const addDatainTable = createAsyncThunk( "Table/add", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params : {collectionName : data.toolkitName}
        };

        const responce = await axios.post(`${serviceURL}/dynamic/add-data`,data, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})





// get toolkit
export const getToolKit = createAsyncThunk( "ToolKit/add", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params : {collectionName : data.collectionName}
        };

        const responce = await axios.get(`${serviceURL}/dynamic/get-schema`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})




//  ========================================= Table APIS ============================================


// get data from table
export const getTableData = createAsyncThunk( "tableData/get", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params : {collectionName : data.collectionName}
        };

        const responce = await axios.get(`${serviceURL}/dynamic/get-all-data-from-collection`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// save Data in table
export const saveDataInTable = createAsyncThunk( "tableData/save", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { sourceCollectionName , targetCollectionName, ...restData } = data;
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params : {sourceSchemaCollection : sourceCollectionName , targetCollection :targetCollectionName}
        };

        const responce = await axios.post(`${serviceURL}/dynamic/add-data-using-schema`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// update Data in table
export const updateDataInTable = createAsyncThunk( "tableData/update", async ( data, { rejectWithValue ,getState })=>{
    try {
        const { sourceCollectionName , targetCollectionName,id , ...restData } = data;
        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params : {sourceSchemaCollection : sourceCollectionName , targetCollection :targetCollectionName , documentId : id}
        };

        const responce = await axios.put(`${serviceURL}/dynamic/update-data-Collection`,restData, config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// delete data from table
export const deleteTableData = createAsyncThunk( "tableData/delete", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
            headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
            params : {collectionName : data.collectionName , id : data.id}
        };

        const responce = await axios.delete(`${serviceURL}/dynamic/delete-record-by-id`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})













// SHOP Flor APIS 


// get data from table
export const getEmployeeData = createAsyncThunk( "employeeData /get", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = { headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },};

        const responce = await axios.get(`${serviceURL}/employeeMaster/getAllEmployeeMasterData`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})


// get camera
export const getCameraData = createAsyncThunk( "cameraData /get", async ( data, { rejectWithValue ,getState })=>{
    try {

        const state = getState();
        const token = state.auth.token; 
        const config = {
             headers: { "Authorization": `Bearer ${token}`,"Content-Type": "application/json" },
             params : {rtspUrl : data.url}

        };

        const responce = await axios.get(`${serviceURL}/layouts/stream-camera`,config);
        return responce.data;

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})
























  



// getToolKit("checkLists")  get tookit 
// copyToolKitIntoOther("checkLists", "AllcheckLists")
// 



  




 

export {
    ToolKitSlice,
    DynamicTableSlice,
}

export const {resetToolKitState} = ToolKitSlice.actions;
export const {resetDynamicTableState} = DynamicTableSlice.actions;



